.Terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    margin: 0;
    padding: 0;
    border: none;
    background-color: #f0f0f0;
  }
  
  .Terms iframe {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    border: none; /* Remove iframe border */
  }