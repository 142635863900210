@import '../../styling/Colors.css';

/* Basic reset for margins and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* Dekstop CSS */


/* Navbar styling */
.dekstop-navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; 
    background-color: var(--navbar-color);
    width: 100vw;
    padding: 0 10px;
    border-bottom: 1px solid white;
    position: sticky;
    top:0;
    z-index: 1000;
}

/* Logo styling */
.navbar-logo {
    background-color: var(--navbar-color);
    cursor: pointer;
    padding: 5px 0;
    margin-left: 5vw;
    font-size: 25px;
    border: 0;
    color: inherit;
    text-decoration: none;
    outline: none;
}

.navbar-logo:hover,
.navbar-logo:active,
.navbar-logo:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}

/* Navbar links styling */
.navbar-links {
    display: flex;
    justify-content: center;
    list-style-type: none;
}

.navbar-links li {
    display: flex;
    width: 15vw;
    height: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.navbar-links a {
    text-decoration: none;
    color: white;
    position: relative;
    font-size: 17px;
    text-align: center;
    width: 100%;
    background-color: var(--navbar-color);
}

.navbar-links a::after {
    content:"";
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 0.25rem;
    background: white;   
    transform: scale(1,0);
    transition: transform 0.3s ease;
}

.navbar-links a:hover::after {
    transform: scale(1,1);
}

.waitlist-button {
    color: white;
    padding: 10px 20px;
    margin-right: 10px;
    background-color: var(--navbar-color);
    border-color: var(--primary-color);
    border-radius: 7px;
    box-shadow: 0px;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
    width: 10vw;
    margin-right: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.waitlist-button:hover {
    background-image: linear-gradient(0deg, rgba(5,146,240,1) -100%, rgba(0,0,0,1) 100%);
}



/* Mobile CSS */

.mobile-navbar {
    display: flex;
    width: 100vw;
    background-color: var(--navbar-color);
    justify-content: space-between;
    align-items: center; 
    width: 100vw;
    padding: 0 10px;
    border-bottom: 1px solid white;
    position: fixed;
    top:0;
    z-index: 1000;
}

.mobile-drawer {
    padding-top: 50px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--navbar-color);
}

.mobile-drawer-navbar-logo {
    cursor: pointer;
    padding: 0 0;
    font-size: 25px;
    border: 0;
    color: inherit;
    text-decoration: none;
    outline: none;
    padding-bottom: 20px;
}

.mobile-navbar-links {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-bottom: 20px;
    /* justify-content: center; */
}

.mobile-navbar-links li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.mobile-navbar-links a {
    text-decoration: none;
    color: white;
    position: relative;
    font-size: 17px;
    text-align: center;
    width: 100%;
    background-color: var(--navbar-color);
}

.mobile-waitlist-button {
    color: white;
    padding: 10px 20px;
    background-color: var(--navbar-color);
    border-color: var(--primary-color);
    border-radius: 7px;
    box-shadow: 0px;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}