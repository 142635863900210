.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 15px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.cookie-banner button {
    background: var(--primary-color, #0592f0);
    border: none;
    color: white;
    padding: 4px 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
    width: 100px;
    height: 40px;
}

.cookie-banner button:hover {
    background: #007acc;
}