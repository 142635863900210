@import "../../styling/Colors.css";

.ContactBackground {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: var(--background-color);
    padding-top: 100px;
    padding-bottom: 50px;
}

@media screen and (max-width: 600px) {
    .ContactBackground {
        padding-top: 50px;
    }
}