.FooterBackground {
    background-color: var(--background-color);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    background-color: var(--background-color);
    border-top: 1px solid white;
    padding: 20px 0;
}

a {
    color: white;
    text-decoration: none;
}

@media screen and (max-width: 900px) {
    .FooterBackground {
        flex-direction: column;
        height: auto;
        padding: 20px 0;
        gap: 15px;
    }
}