@import '../../styling/Colors.css';

:root {
    --about-height: 32vw;
}


.BackgroundContainerAbout {
    background-color: var(--box-color);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.AboutUIContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
}


@media screen and (max-width: 600px) {
    .AboutUIContainer {
      flex-direction: column-reverse;
    }
}

.AboutLeftSide{
    display: flex;
    width: 55vw;
    flex-direction: column;
    align-items: center;
    height: var(--about-height);
    padding-top: 3vw;
}

@media screen and (max-width: 600px) {
    .AboutLeftSide {
        width: 100vw;
        height: 150vw;
        padding-top: 20px;
    }
}

.Answer {
    width: 35vw;
    padding-top: 30px;
    color: var(--second-text-color);
    text-align: justify;
    font-size: 1.2vw;
}

@media screen and (max-width: 600px) {
    .Answer {
        width: 80vw;
        font-size: 4vw;
    }
}

.AboutRightSide{
    display: flex;
    width: 45vw;
    flex-direction: column;
    justify-content: center;
    background-color: var(--box-color);
    height: var(--about-height);
}

@media screen and (max-width: 600px) {
    .AboutRightSide {
        width: 100vw;
        height: 80vw;
        object-fit: fill;
    }
}

.AboutRightSide > img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

