.AppText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70vw;
}

@media screen and (max-width: 600px) {
    .AppText {
        flex-direction: column;
        padding-top: 50px;
    }
}

.AppTextLeft {
    padding-top: 40px;
    padding-bottom: 40px;
}

.WelcomeText {
    color: var(--primary-color);
    background-color: var(--background-color);
    width: 30vw;
    font-weight: 800;
    opacity: 0;
    transform: translateY(20px);
    animation: slideUp 0.7s ease-out forwards;
    text-justify: inter-word;
    font-size: 3.5vw;
}

@media screen and (min-width:600px) and (max-width: 900px) {
    .WelcomeText {
        font-size: 4.5vw;
    }
}

@media screen and (max-width: 600px) {
    .WelcomeText {
        width: 70vw;
        text-align: center;
        font-size: 10vw;
    }
}

.SecondaryWelcomeText {
    color: var(--second-text-color);
    font-size: 1.5vw;
    width: 25vw;
    font-weight: 500;
    font-style: italic;
    opacity: 0;
    transform: translateY(20px);
    animation: slideUp 0.7s ease-out 0.35s forwards;
}

@media screen and (min-width:600px) and (max-width: 900px) {
    .SecondaryWelcomeText {
        font-size: 2vw;
    }
}

@media screen and (max-width: 600px) {
    .SecondaryWelcomeText {
        width: 70vw;
        font-size: 4vw;
        text-align: center;
    }
}

.WelcomeDigitalText {
    color: white;
}

.UICards {
    display: flex;
    justify-content: space-between;
    width: 85vw;
}

@media screen and (max-width: 600px) {
    .UICards {
        flex-direction: column;
        align-items: center;
        height: 115vw;
    }
}

.MyFilesUI, .EditPageUI {
    display: flex;
    width: 39vw;
    aspect-ratio: 2880/2140;
}

@media screen and (max-width: 600px) {
    .MyFilesUI, .EditPageUI {
        width: 70vw;
        padding-top: 10vw;
    }
}

.MyFilesUIImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 50px 1em rgba(5, 146, 240, 0.2);
    opacity: 0;
    transform: translateY(20px);
    animation: slideUp 0.7s ease-out 0.7s forwards;
}

.EditPageUIImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 50px 1em rgba(5, 146, 240, 0.2);
    opacity: 0;
    transform: translateY(20px);
    animation: slideUp 0.7s ease-out 0.7s forwards;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}