/* index.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body, html {
  height: 100%;
  width: 100vw;
  margin: 0;
  font-family: 'Montserrat', serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scrollbar-width: thin;
}