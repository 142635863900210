@import '../../styling/Colors.css';

.PaddingTop {
  padding-top: 40px;
}

@media screen and (max-width: 600px) {
  .PaddingTop {
    padding-top: 75px;
  }
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70vw;
  text-align: center;
}

.Header > h1 {
  padding-left: 10px;
  width: inherit;
  padding-top: 10px;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 4rem;
}

@media screen and (max-width: 600px) {
  .Header > h1 {
    font-size: 10vw;
  }
}

.BenefitsLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70vw;
}

@media screen and (max-width: 600px) {
  .BenefitsLine {
    flex-direction: column;
  }
}

.BenefitCard {
    width: 34vw;
    border-radius: 10px;
    height: 125px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    white-space: normal;
    margin-top: 2vw;
    cursor: pointer;
    perspective: 1000px;
}

@media screen and (max-width: 600px) {
  .BenefitCard {
    flex-direction: column;
    width: 100%;
  }
}

.BenefitCard > h1 {
    width: inherit;
    padding-left: 10px;
    padding-top: 10px;
}

.BenefitCard > p {
    padding-left: 10px;
    padding-top: 10px;
}

.front,
.back {
  display: flex;
  border-radius: 6px;
  background-position: center;
  background-size: cover;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
  /* overflow: hidden; */
}

.front {
  background-size: cover;
  background: linear-gradient(0deg, var(--primary-color) -100%, var(--box-color) 80%);
  background-blend-mode: overlay;
  padding: 2rem;
  font-size: 1.618rem;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, var(--primary-color) -300%, var(--box-color) 10%);
    opacity: .25;
    z-index: -1;
  }
  .BenefitCard:hover & {
    transform: rotateX(180deg);
  }
  .BenefitCard:nth-child(even):hover & {
    transform: rotateX(-180deg);
  }
}

.back {
  background: linear-gradient(0deg, var(--primary-color) -300%, var(--box-color) 20%);
  transform: rotateX(-180deg);
  padding: 0 2em;
  
  .BenefitCard:hover & {
    transform: rotateX(0deg);
  }
}