.AppTitleContainer {
    display: flex;
    align-items: center;
}

.AppIcon {
    height: 37.5px;
    aspect-ratio: 1/1;
}

.AppTitle {
    color: #fff;
    font-size: inherit;
}

.AppTitleCloud {
    color: var(--primary-color);
}
