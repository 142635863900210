/* colors.css */

/* Define color variables */
:root {
    --primary-color: #0592f0;  /* #6495ED Blue */
    --primary-light: #6885bc;  /* Lighter Blue */

    --accent-color: #C17C74; /* Light red */
  
    --navbar-color: #030303;  /* Light Gray */
    --background-color: #111111; /*1c1c1c*/
    --background-secondary-color: #222222; /*1c1c1c*/
    --text-color: #EFEFEF;  /* Dark Gray */
    --second-text-color: #cccccc;
    --box-color: rgb(26, 36, 50); /* Dark Blue */
  }
  