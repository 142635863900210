@import '../../styling/Colors.css';

.BackgroundContainer {
    background-color: var(--background-color);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.BackgroundContainer > h1 {
    padding-top: 100px;
    font-weight: 200;
    font-size: 2.75rem;
    border-bottom: 1px solid white;
    color: var(--primary-color);
    width: 80vw;
}