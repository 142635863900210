/* InquiryForm.css */
.InquiryFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw; /* Flexible width for smaller screens */
  max-width: 400px;
  margin: 0;
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 25px;
}

.InquiryFormHeader {
  color: var(--primary-color);
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
}

.SuccessMessage {
  color: #00fa15;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
}

.ErrorMessage {
  color: #ff0000;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
}

.InquiryForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.FormLabel {
  color: #fff;
  font-weight: bold;
  margin: 10px 0px;
}

.InquiryForm input,
.InquiryForm textarea {
  padding: 8px;
  margin-top: 5px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  width: 100%;
}

.InquiryForm button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 0.8rem;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  align-self: center;
}

.InquiryForm button:hover {
  background-color: var(--primary-color);
}

select {
  border-radius: 4px;
  padding: 10px 14px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5); /* Add a subtle shadow */
}

select:hover {
  background-color: #f1f1f1; /* Slightly darker background on hover */
}

@media (max-width: 600px) {
  .InquiryFormContainer {
    width: 90vw;
  }

  .InquiryForm button {
    width: 50vw;
  }
}
